<template>
  <div class="inner-layout">

    <div class="page-title">마이페이지</div>

    <mypage-tab/>

    <div class="layout-filter-box">
      <mini-filter :opt="opt.activity" :target="activity" :targetTitle="'activity'" :title="'활동'" :inputType="'radio'" @set="setMiniFilter"/>
    </div>

    <div class="w-960 mx-auto">
      
      <div class="community-list activity-list">
        <template v-for="(item, index) in list">
          <div class="community-item" :key="`activity-${index}`">
            <div class="item-con cursor-pointer" @click="goDetail(item)">
              <span class="item-post">[{{ item.parentCateName }}/{{ item.categoryName }}]</span>
              <div class="item-post mb-15">{{ item.title }}</div>
              <div class="activity-text">
                <span class="date">{{ $getDateFormat(item.createDate, 'Y.m.d') }} | </span>
                <span v-if="item.type === 0" class="activity-title"> 게시글에 <span class="color-1">댓글</span>을 등록하셨습니다.</span>
                <span v-if="item.type === 1" class="activity-title"> 게시글에 <span class="color-1">답글</span>을 등록하셨습니다.</span>
                <span v-if="item.type === 2" class="activity-title"> 게시글에 <span class="color-1">추천</span>을 하셨습니다.</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div v-if="!list || list.length == 0" class="noDataMessage" style="border: 0">{{ $msg('nodata2') }}</div>

    <infinite-loading ref="infiniteLoading" spinner="spiral" :identifier="identifier" @infinite="infiniteHandler">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import listMixin from "@/mixins/listMixin"
export default {
  name: "mypageActivity",
  mixins: [listMixin],
  data() {
    return {
      pageSize: 20,
    }
  },
  methods: {
    async infiniteHandler($state) {
      try {
        const { result, list } = await this.$api.getMylog({
          type: this.activity,
          page: this.page,
          pageSize: this.pageSize,
        })
        if (result === 'success') {
          if (list.length > 0) {
            this.list = this.page == 1 ? list : this.list.concat(list)
            setTimeout(() => {
              this.page ++
              $state.loaded()
            }, 1000)
          }
          else {
            $state.complete();
          }
        }
        else {
          $state.complete();
        }
      } catch (error) {
        console.log(error);
      }
    },
    setMiniFilter(target, value) {
      this[target] = value
    },
    goDetail(item) {
      this.setBeforeContent()
      if (item.parentCategory === 10) this.$router.push(`/tip/${item.boIdx}`)
      if (item.parentCategory === 20) this.$router.push(`/lab/${item.boIdx}`)
      if (item.parentCategory === 80) this.$router.push(`/ggomunity/${item.boIdx}`)
    }
  }
}
</script>
